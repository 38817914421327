import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { map } from "lodash"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { MetaTags } from "react-meta-tags"
import {
  Badge,
  Container,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
  Button,
} from "reactstrap"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  CardTitle,
  FormGroup,
  Spinner,
} from "reactstrap"
import Select from "react-select"
import classnames from "classnames"

//actions
import {
  getClients,
  createQuatation,
  getProducts,
  getQProductPrice,
  getQuotationDetail,
  getBankDetails,
  updateQuatation,
} from "store/actions"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import FormEditors from "./Editor"
import { Notification } from "components/Common/Notification"
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import EditModal from "./EditModal"

const UpdateQuotations = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  //redux state
  const {
    loading,
    quotationLoading,
    clients,
    products,
    QProductPrice,
    quotationCurd,
    bankDetailsData,
    quoteDetails,
  } = useSelector(state => ({
    loading: state.StoreItems.loading,
    quotationLoading: state.Orders.quotationLoading,
    clients: state.Clients.clients,
    products: state.Products.products,
    QProductPrice: state.Orders.QProductPrice,
    quotationCurd: state.Orders.quotationCurd,
    bankDetailsData: state.Orders.bankDetails,
    quoteDetails: state?.Orders?.quotationDetails,
  }))
  const [selectedProductId, setSelectedProductId] = useState([])
  const [selectedProduct, setSelectedProduct] = useState("Search a product")
  const [selectedClient, setSelectedClient] = useState("Search a Client")
  const [selectedClientId, setSelectedClientId] = useState("")

  const [isOpen, setIsOpen] = useState({ status: false, data: "", client: "" })

  console.log(selectedProduct)

  const [searchText, setSearchText] = useState("")
  const [searchClientText, setSearchClientText] = useState("")
  const [qty, setQty] = useState("")

  const [quotationitem, setQuotationitems] = useState([])
  const [percentage, setPercentage] = useState(0)
  const [rawData, setRawData] = useState({
    client: "",
    quotationitem: [],
  })

  //EDITOR
  const [letter_head, setLetterHead] = useState(
    `<p><strong>Dear Sir/Madam,</strong></p><p></p>`
  )
  const [bank_details, setBankDetails] = useState(bankDetailsData?.bank_details)
  const [terms, setTerms] = useState(bankDetailsData?.trems_and_conditions)
  const [letter_middle, setLetter_middle] = useState("")
  const [warrenty, setWarrantyClause] = useState(
    bankDetailsData?.warrenty_clauses
  )

  const [activeTab, setactiveTab] = useState({
    id: "1",
  })

  useEffect(() => {
    setBankDetails(bankDetailsData?.bank_details)
    setTerms(bankDetailsData?.trems_and_conditions)
    setWarrantyClause(bankDetailsData?.warrenty_clauses)
  }, [bankDetailsData])

  useEffect(() => {
    setSelectedClient(quoteDetails?.client_name)
    setSelectedClientId(quoteDetails?.client)
    setSelectedProduct(
      quoteDetails?.quotationitem?.map(item => item?.product_name)
    )
    setSelectedProductId(
      quoteDetails?.quotationitem?.map(item => item?.product)
    )
  }, [quoteDetails])

  useEffect(() => {
    dispatch(getBankDetails())
  }, [dispatch])

  useEffect(() => {
    dispatch(getQuotationDetail(params?.id))
  }, [dispatch, params?.id])

  const ProductPrice = parseInt(QProductPrice?.cost)

  const totalPriceCalc = (ProductPrice * percentage) / 100 + ProductPrice

  useEffect(() => {
    if (quotationCurd?.id) {
      dispatch(getQuotationDetail(quotationCurd?.id))
    }
  }, [quotationCurd])

  useEffect(() => {
    setPercentage(QProductPrice?.profit)
  }, [QProductPrice])

  useEffect(() => {
    setRawData({
      ...rawData,
      quotationitem: {
        ...rawData.quotationitem,
        ["profit"]: percentage,
        ["price"]: totalPriceCalc,
      },
    })
  }, [percentage])

  useEffect(() => {
    dispatch(getClients(searchClientText, ""))
  }, [dispatch, searchClientText])

  console.log(rawData)

  useEffect(() => {
    dispatch(getProducts(searchText, ""))
  }, [dispatch, searchText])

  useEffect(() => {
    setQuotationitems(quoteDetails?.quotationitem)
  }, [quoteDetails])

  const onAddFormRow = () => {
    const modifiedRows = [...quotationitem]
    modifiedRows.push({
      id: modifiedRows.length + 1,
      qty: qty,
      product: selectedProductId,
      client: selectedClientId,
      ...rawData.quotationitem,
    })

    if (!rawData.client) {
      Notification({
        type: "warning",
        message: "Please Select Client",
        title: "Try Again",
      })
    } else {
      setQuotationitems(modifiedRows)
    }

    setTimeout(() => {
      handleClearStates()
    }, 1000)
  }
  console.log(quotationitem)

  const onDeleteFormRow = id => {
    console.log(id)
    if (id) {
      // const modifiedRows = [...quotationitem]
      const modifiedRows = quotationitem?.filter(x => x?.id !== id)
      // setQuotationitems(modifiedRows)

      console.log(modifiedRows)
      const data = {
        status: status,
        contact_person_phone: contact_person_phone,
        contact_person: contact_person,
        tax_percentage: taxPercentage,
        terms: terms,
        quotationitem: modifiedRows,
        warrenty: warrenty,
        bank_details: bank_details,
        letter_head: letter_head,
        letter_middle: letter_middle,
        isDuplicate: false,
        discount_percentage: discount,
      }
      dispatch(updateQuatation(data, "", params?.id, "", ""))
    }
  }
  // quotationitem
  const onSubmitQuotation = () => {
    const data = {
      status: status,
      contact_person_phone: contact_person_phone,
      contact_person: contact_person,
      tax_percentage: taxPercentage,
      terms: terms,
      quotationitem: quotationitem,
      warrenty: warrenty,
      bank_details: bank_details,
      letter_head: letter_head,
      letter_middle: letter_middle,
      isDuplicate: false,
      discount_percentage: discount,
    }
    dispatch(updateQuatation(data, "", params?.id, history, ""))
  }

  //setore item from and search
  function handlerFinalValue(event) {
    dispatch(getQProductPrice(event.value))
    // setProductId(event.value)
    setSelectedProduct(event.label)
    setRawData({
      ...rawData,
      quotationitem: {
        ["product"]: event.value,
        ["productName"]: event.label,
      },
    })
  }
  console.log(products?.results)

  const optionGroup1 = [
    {
      options: products?.results?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?.id,
      })),
    },
  ]

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  function handlerClientFinalValue(event) {
    console.log(event)
    setSelectedClientId(event?.value)
    setRawData({
      ...rawData,
      ["client"]: event.value,
    })
  }
  const optionGroup2 = [
    {
      options: clients?.results?.map((result, index) => ({
        key: index,
        label: result.name,
        value: result.id,
      })),
    },
  ]

  const handleClientEnters = textEntered => {
    setSearchClientText(textEntered)
  }

  const Role = sessionStorage.getItem("role")

  const disabledBtn = () => {
    if (rawData?.orderitem?.product && rawData?.client) {
      return true
    } else {
      return true
    }
  }

  const [total, setTotal] = useState()

  const handleTotal = e => {
    setRawData({
      ...rawData,
      quotationitem: {
        ...rawData.quotationitem,
        ["price"]: e.target.value,
      },
    })
    if (e) {
      setTotal(e.target.value)
    } else {
      setTotal(totalPriceCalc)
    }
  }

  useEffect(() => {
    setTotal(totalPriceCalc)
  }, [totalPriceCalc, QProductPrice])

  // const newPercentage = Math.abs(((total - QProductPrice.cost) * 100) / QProductPrice.cost)

  // useEffect(() => {
  //   setPercentage(newPercentage)
  // }, [])

  const handleClearStates = () => {
    setSelectedClient("Search a Client")
    setSelectedProduct("Search a product")

    const profitInput = document.getElementById("profit-percentage")
    if (profitInput) {
      profitInput.value = 0
    } else {
      console.error("Profit input element not found in the DOM.")
    }

    const priceInput = document.getElementById("total-price")
    if (priceInput) {
      priceInput.value = 0
    } else {
      console.error("Price input element not found in the DOM.")
    }
  }

  console.log(selectedClient)
  console.log(selectedClientId)

  const handleEdit = item => {
    setIsOpen({ status: true, data: item })
  }

  const onCloseClick = () => {
    setIsOpen(false)
  }

  const [status, setStatus] = useState("")
  const [taxPercentage, setTaxPercentage] = useState("")
  const [contact_person, setContact_person] = useState("")
  const [contact_person_phone, setContact_person_phone] = useState("")

  const handleStatus = e => {
    setStatus(e)
  }

  console.log(status)
  console.log(quoteDetails)

  useEffect(() => {
    setStatus(quoteDetails?.status)
    setContact_person_phone(quoteDetails?.contact_person_phone)
    setContact_person(quoteDetails?.contact_person)
    setTaxPercentage(quoteDetails?.tax_percentage)
  }, [quoteDetails])

  const handleSubmit = () => {
    const data = {
      status: status,
      contact_person_phone: contact_person_phone,
      contact_person: contact_person,
      tax_percentage: taxPercentage,
      terms: terms,
      warrenty: warrenty,
      bank_details: bank_details,
      letter_head: letter_head,
      letter_middle: letter_middle,
      isDuplicate: false,
      discount_percentage: discount,
    }

    dispatch(updateQuatation("", data, params?.id, history, ""))
  }
  const [checkbox, setCheckbox] = useState()

  const handleChecked = value => {
    setCheckbox(value)
  }
  const [discount, setDiscount] = useState("")

  useEffect(() => {
    setDiscount(quoteDetails?.discount_percentage)
  }, [quoteDetails])

  return (
    <>
      <EditModal
        show={isOpen?.status}
        onCloseClick={onCloseClick}
        data={isOpen?.data}
        client={quoteDetails?.client}
      />
      <MetaTags>
        <title>Quotation | Indtech </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="Quotation" breadcrumbItem="Update Quotation" />

        <Container fluid>
          <div className="container-fluid">
            {/* uploading */}
            <Row>
              <Col lg={1}></Col>
              <Col lg={10}>
                <Card>
                  <CardBody>
                    <div className="h4 mb-4 d-flex align-items-center justify-content-between">
                      <div> Quotation Details</div>
                      <div>
                        <Button
                          color="success"
                          className="px-5"
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>{" "}
                      </div>
                    </div>

                    <Form className="repeater" encType="multipart/form-data">
                      <div className="row">
                        <div className="col-3">
                          <label>Contact Person</label>
                          <input
                            className="form-control"
                            type="text"
                            value={contact_person}
                            onChange={e => {
                              setContact_person(e.target.value)
                            }}
                          />
                        </div>
                        <div className="col-3">
                          <label>Contact No</label>
                          <input
                            className="form-control"
                            type="number"
                            value={contact_person_phone}
                            onChange={e => {
                              setContact_person_phone(e.target.value)
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <label>Discount</label>
                          <input
                            className="form-control"
                            type="text"
                            value={discount}
                            onChange={e => {
                              setDiscount(e.target.value)
                            }}
                          />
                        </div>

                        <div className="col-2">
                          <label>Status</label>
                          <select
                            className="form-control"
                            value={status}
                            onChange={e => {
                              handleStatus(e.target.value)
                            }}
                          >
                            <option value="pending">Pending</option>
                            <option value="approved">Approval</option>
                            <option value="rejected">Rejected</option>
                          </select>
                        </div>
                        {/* <div className="col-2">
                          <label className="mx-1">Duplicate</label>
                          <input
                            name="isDuplicate"
                            className="form-check-input"
                            type="checkbox"
                            id="isDuplicate"
                            checked={quoteDetails?.isDuplicate}
                            // value={quoteDetails?.isDuplicate}
                            onChange={e => handleChecked(e.target.checked)}
                          />
                        </div> */}

                        {/* <Row>
                          {Role == "client" ? (
                            <></>
                          ) : (
                            <Col lg={12} className="mb-3">
                              <FormGroup className="mb-3">
                                <Label>Client</Label>

                                <div className="col-md-12"></div>
                                <div className="mb-3 ajax-select mt-1 mt-lg-0 select2-container">
                                  <Select
                                    onInputChange={handleClientEnters}
                                    value={selectedClientId}
                                    placeholder={selectedClient}
                                    onChange={handlerClientFinalValue}
                                    options={optionGroup2}
                                    classNamePrefix="select2-selection"
                                    isLoading={true}
                                    required={"required"}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                          )}

                          <Col lg={6} md={6} sm={12} className="mb-3">
                            <FormGroup className="mb-3">
                              <Label>Products</Label>
                              <div className="col-md-12"></div>
                              <div className="ajax-select mt-1 mb-3 mt-lg-0 select2-container">
                                <Select
                                  onInputChange={handleEnters}
                                  value={selectedProductId}
                                  placeholder={selectedProduct}
                                  onChange={handlerFinalValue}
                                  options={optionGroup1}
                                  classNamePrefix="select2-selection"
                                  isLoading={true}
                                  required="required"
                                />
                              </div>
                              {QProductPrice?.cost && (
                                <span className="mt-2 text-muted">
                                  Product Cost :
                                  <Badge
                                    className={
                                      "font-size-14 p-2 mx-3 badge-soft-success"
                                    }
                                    pill
                                  >
                                    {QProductPrice?.cost}
                                  </Badge>
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                          <Col
                            lg={totalPriceCalc ? 2 : 4}
                            md={3}
                            sm={12}
                            className=""
                          >
                            <label htmlFor="profit-percentage">
                              Profit Percentage
                            </label>
                            <input
                              type="number"
                              className="form-control mt-1 mt-lg-0"
                              id="profit-percentage"
                              required
                              min={1}
                              defaultValue={QProductPrice.profit}
                              onChange={e => setPercentage(e.target.value)}
                            />
                          </Col>
                          <Col
                            lg={totalPriceCalc ? 2 : 2}
                            md={3}
                            sm={12}
                            className=""
                          >
                            <label htmlFor="quantity">Quantity</label>
                            <input
                              type="number"
                              className="form-control mt-1 mt-lg-0"
                              id="qty"
                              required
                              min={1}
                              defaultValue={0}
                              onChange={e => setQty(e.target.value)}
                            />
                          </Col>
                          {totalPriceCalc ? (
                            <Col lg={2} md={3} sm={12} className="">
                              <label htmlFor="total-price">Total Price</label>
                              <input
                                type="number"
                                className="form-control mt-1 mt-lg-0 text-warning"
                                id="total-price"
                                required
                                min={1}
                                value={total ? total : ""}
                                onChange={e => handleTotal(e)}
                              />
                            </Col>
                          ) : (
                            <></>
                          )}
                          {totalPriceCalc || total ? (
                            <p
                              style={{
                                width: "fit-content",
                                marginLeft: "auto",
                                padding: "0",
                              }}
                            >
                              Total Price :
                              <Badge
                                className={
                                  "font-size-14 p-2 mx-3 badge-soft-success"
                                }
                                pill
                              >
                                {total ? total : totalPriceCalc}
                              </Badge>
                            </p>
                          ) : (
                            <></>
                          )}
                          <Col
                            lg={12}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <input
                              type="button"
                              className={`btn btn-dark mr-lg-0 ${
                                disabledBtn() == false && "disabled"
                              }`}
                              value="Add to Quotation"
                              onClick={() => onAddFormRow()}
                              style={{
                                marginTop: "1rem",
                                pointerEvents:
                                  rawData.product == false && "none",
                              }}
                            />
                          </Col>
                        </Row> */}
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={1}></Col>

              {quoteDetails?.quotationitem?.length > 0 && (
                <>
                  <Col lg={1}></Col>
                  <Col lg={10}>
                    <Card>
                      <CardBody>
                        <CardTitle className="h4 mb-4">
                          Quotations Items{" "}
                        </CardTitle>
                        {loading ? (
                          <Spinner type="grow" color="gray" />
                        ) : (
                          <Form
                            className="repeater"
                            encType="multipart/form-data"
                          >
                            <div>
                              {map(
                                quoteDetails?.quotationitem,
                                (item, index) => (
                                  <Row key={index}>
                                    <Row className="text-muted mt-4">
                                      <Col lg={5} md={5}>
                                        <p>
                                          <i className="mdi mdi-chevron-right text-primary me-1" />
                                          Product : {item?.product_name || ""}
                                        </p>
                                      </Col>
                                      <Col lg={3} md={3}>
                                        <p>
                                          Price : {item?.mrp_sub_total || ""}
                                        </p>
                                      </Col>

                                      <Col lg={4} md={4} className="">
                                        <div
                                          className="d-flex align-items-center "
                                          // style={{ maxWidth: "200px" }}
                                        >
                                          <i
                                            className="bx bx-edit-alt font-size-16 text-primary"
                                            onClick={() => handleEdit(item)}
                                            title="edit"
                                            style={{ cursor: "pointer" }}
                                          />{" "}
                                          &nbsp; &nbsp; &nbsp; &nbsp;
                                          <i
                                            style={{ cursor: "pointer" }}
                                            className="fa fa-trash text-danger"
                                            onClick={() =>
                                              onDeleteFormRow(item.id)
                                            }
                                          ></i>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Row>
                                )
                              )}
                            </div>
                          </Form>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={1}></Col>
                </>
              )}
              <Col lg={1}></Col>
              <Col lg={10}>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">{activeTab.title}</h4>

                    <Nav pills className="bg-light rounded" role="tablist">
                      <NavItem className="mx-2">
                        <NavLink
                          className={classnames({
                            active: activeTab.id === "1",
                          })}
                          onClick={() => {
                            setactiveTab({
                              ...activeTab,
                              id: "1",
                            })
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-end">
                            Letter Head
                            <i className="bx bx-chevrons-right font-size-20 mx-2"></i>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem className="mx-2">
                        <NavLink
                          className={classnames({
                            active: activeTab.id === "2",
                          })}
                          onClick={() => {
                            setactiveTab({
                              ...activeTab,
                              id: "2",
                            })
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-end">
                            Bank Details
                            <i className="bx bx-chevrons-right font-size-20 mx-2"></i>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem className="mx-2">
                        <NavLink
                          className={classnames({
                            active: activeTab.id === "3",
                          })}
                          onClick={() => {
                            setactiveTab({
                              ...activeTab,
                              id: "3",
                            })
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-end">
                            Terms and Conditions
                            <i className="bx bx-chevrons-right font-size-20 mx-2"></i>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem className="mx-2">
                        <NavLink
                          className={classnames({
                            active: activeTab.id === "4",
                          })}
                          onClick={() => {
                            setactiveTab({
                              ...activeTab,
                              id: "4",
                            })
                          }}
                        >
                          Warranty clause
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={activeTab.id} className="mt-4">
                      <TabPane tabId="1">
                        {activeTab.id === "1" && (
                          <>
                            <FormEditors
                              content={letter_head}
                              setContent={setLetterHead}
                            />

                            <div className="d-flex align-items-center justify-content-end mt-2">
                              <button
                                className="btn btn-light w-fit-content d-flex align-items-center justify-content-center"
                                onClick={() => {
                                  setactiveTab({
                                    ...activeTab,
                                    id: "2",
                                  })
                                }}
                              >
                                Next
                                <i className="bx bx-chevrons-right bx-fade-right font-size-20"></i>
                              </button>
                            </div>
                          </>
                        )}
                      </TabPane>

                      <TabPane tabId="2">
                        {activeTab.id === "2" && (
                          <Form method="post">
                            <FormEditors
                              content={bank_details ? bank_details : ""}
                              setContent={setBankDetails}
                            />
                            <textarea
                              rows="5"
                              type="text"
                              className="form-control mt-4 pt-4"
                              id=""
                              placeholder={"We are expecting your valuable...."}
                              value={letter_middle}
                              onChange={e => setLetter_middle(e.target.value)}
                            />
                            <div className="d-flex align-items-center justify-content-end mt-2">
                              <button
                                className="btn btn-light w-fit-content d-flex align-items-center justify-content-center"
                                onClick={() => {
                                  setactiveTab({
                                    ...activeTab,
                                    id: "3",
                                  })
                                }}
                              >
                                Next
                                <i className="bx bx-chevrons-right bx-fade-right font-size-20"></i>
                              </button>
                            </div>
                          </Form>
                        )}
                      </TabPane>
                      <TabPane tabId="3">
                        {activeTab.id === "3" && (
                          <>
                            <FormEditors
                              content={terms ? terms : ""}
                              setContent={setTerms}
                            />
                            <div className="d-flex align-items-center justify-content-end mt-2">
                              <button
                                className="btn btn-light w-fit-content d-flex align-items-center justify-content-center"
                                onClick={() => {
                                  setactiveTab({
                                    ...activeTab,
                                    id: "4",
                                  })
                                }}
                              >
                                Next
                                <i className="bx bx-chevrons-right bx-fade-right font-size-20"></i>
                              </button>
                            </div>
                          </>
                        )}
                      </TabPane>
                      <TabPane tabId="4">
                        {activeTab.id === "4" && (
                          <>
                            <FormEditors
                              content={warrenty ? warrenty : ""}
                              setContent={setWarrantyClause}
                            />
                            <div>
                              <Row>
                                <Col lg="6" md="6"></Col>
                                <Col lg="3" md="3">
                                  {quotationCurd?.id && (
                                    <div className="text-sm-end mt-2">
                                      <Link
                                        to={`/quotation/pdf/${quotationCurd?.id}`}
                                        type="button"
                                        className="btn btn-outline-light d-flex mt-4 w-auto"
                                        style={{
                                          marginLeft: "auto",
                                          alignItems: "center",
                                          width: "fit-content",
                                          border: "1px solid #cccc",
                                        }}
                                      >
                                        PDF
                                        <i className="mdi mdi-download d-block font-size-16 mx-1"></i>
                                      </Link>
                                    </div>
                                  )}
                                </Col>
                                <Col lg="3" md="3">
                                  <div className="text-sm-end mt-2">
                                    <Link
                                      to="#"
                                      className="btn btn-success"
                                      onClick={onSubmitQuotation}
                                    >
                                      Confirm Quotation
                                      {quotationLoading ? (
                                        <>
                                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                        </>
                                      ) : (
                                        <i className="mdi mdi-truck-fast mx-2" />
                                      )}
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </>
                        )}
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={1}></Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateQuotations

UpdateQuotations.propTypes = {
  history: PropTypes.object,
}
